<!-- OV/NF table -->
<div *ngIf="data" mat-dialog-content>
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <p>
    {{ description }}
  </p>
</div>

<div *ngIf="data.isHubReturn" fxLayout="row">
  <mat-form-field class="col-md-6">
    <mat-select  placeholder="Selecione o Hub" name="HubId" [(ngModel)]="selectedHub" required>
      <mat-option *ngFor="let hub of hubList" [value]="hub.name"  >
        {{ hub.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<app-table
  [data]="existing"
  [columns]="columns"
  [this]="this"
  (check)="addToList($event)"
  showCheckbox = "true"
></app-table>

<!-- buttons -->
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button mat-button class="red-button" (click)="submit()" [disabled]="data.isHubReturn ? (toRemove.length === 0 || !selectedHub) : (toRemove.length === 0)">
    {{ 'ATTACHMENTS/remove-nf' | translate }}
  </button>
</div>
