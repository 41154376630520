import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var OVService = /** @class */ (function () {
    function OVService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    OVService.prototype.getAll = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/' + id, { headers: header });
    };
    OVService.prototype.saveMultipleOV = function (listOV) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV', {
            listOV: listOV
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.saveMultipleZH = function (listZH) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/zh', {
            listZH: listZH
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.saveMultipleZRS = function (listZRS) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/zrs', {
            listZRS: listZRS
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.getNfRelationsByMdr = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/' + id + '/relations', { headers: header });
    };
    OVService.prototype.getNfNotRelationsByMdr = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/' + id + '/drId', { headers: header });
    };
    OVService.prototype.getNfByMdr = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/nf/' + id, { headers: header })
            .map(function (response) {
            var OVdata = response;
            return OVdata;
        });
    };
    OVService.prototype.addToDR = function (mdrId, drId, toAdd) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
            .set('x-api-key', api_key);
        return this.http.post(base_url + ("OV/" + mdrId + "/relations"), {
            drId: drId,
            ovs: toAdd.filter(function (row) { return row.type === 'zvsd1'; })
                .map(function (row) { return row.referenceNoNf; }),
            nfs: toAdd.filter(function (row) { return row.type === 'nfe'; })
                .map(function (row) { return row.invoiceNumber; }),
        }, { headers: header });
    };
    OVService.prototype.sendHubReturn = function (toRemove, hub) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
            .set('x-api-key', api_key);
        var body = {
            ov: toRemove,
            hubToSend: hub
        };
        return this.http.post(base_url + 'OV/sendDRToHub', body, { headers: header });
    };
    OVService.prototype.removeFromMDR = function (mdrId, body) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
            .set('x-api-key', api_key);
        var options = {
            headers: header,
            body: body
        };
        return this.http.delete(base_url + ("OV/" + mdrId + "/relations"), options);
    };
    OVService.prototype.validateOVs = function (idList) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/validate', {
            idList: idList
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.validateHub = function (hubName) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/validateHUB', {
            hub: hubName
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.getVehicle = function (ovs, products) {
        if (products === void 0) { products = null; }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('ovs', '[' + ovs.toString() + ']');
        if (products) {
            params = params.set('products', '[' + products.toString() + ']');
        }
        return this.http.get(base_url + 'OV/vehicle', { headers: header, params: params });
    };
    OVService.prototype.searchByOV = function (ov) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/search/' + ov, { headers: header });
    };
    OVService.prototype.getFilesFromMdrByOv = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/mdr/' + idMdr + '/zvsd1', { headers: header });
    };
    OVService.prototype.getFilesFromMdrByNf = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/mdr/' + idMdr + '/nfe', { headers: header });
    };
    OVService.prototype.getFilesFromMdrByOvForPOD = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/zvsd1', { headers: header });
    };
    OVService.prototype.getFilesFromMdrByNfForPOD = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/nfe', { headers: header });
    };
    OVService.prototype.getFilesFromMdrByCteForPOD = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/cte', { headers: header });
    };
    OVService.prototype.getFilesFromMdrManually = function (idMdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'file/mdr/' + idMdr + '/manual', { headers: header });
    };
    return OVService;
}());
export { OVService };
