<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <!-- this creates a google map on the page with the given lat/lng from -->
      <!-- the component as the initial center of the map: -->
      <div class="row container-title">
        <div class="col-md-10">
          {{ 'occurrence' | translate }}
        </div>
        <div class="col-md-2">
          <span *ngIf="role !== 3" (click)='changeShowDeleted()'><i *ngIf="showDeleted" class="fa fa-eye" style="color: gray;" matTooltip="Mostrar Deletadas"></i> </span>
          <span *ngIf="role !== 3" (click)='changeShowDeleted()'><i *ngIf="!showDeleted" class="fa fa-eye-slash" style="color: gray;" matTooltip="Mostrar Deletadas"></i> </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <form #form="ngForm" *ngIf="mdr.status !== 'MDR em validação'">
            <mat-form-field class="col-lg-4 col-md-6">
              <mat-select placeholder="{{ 'OCURRENCE/type' | translate }}" [(ngModel)]="occurrence.uoc"
                (selectionChange)="getUOCValue()" name="uoc" required>
                <mat-option *ngFor="let elem of uoc" [value]="elem.name">
                  {{ elem.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-lg-4 col-md-6">
              <input matInput placeholder="Descrição" [(ngModel)]="occurrence.text" name="text" required>
            </mat-form-field>
            <button mat-raised-button
              class="login"
              [disabled]="!form.valid || role === 5 || role === 3"
              class="green-button text-white col-lg-4 col-md-12"
              (click)="createOccurrence()">
              {{ 'OCURRENCE/create' | translate }}
            </button>

            <!-- tabela de occurrences-->

            <table mat-table [dataSource]="dataOccurrence" multiTemplateDataRows class="mat-elevation-z8">
              <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                <th mat-header-cell *matHeaderCellDef class="top"> {{ 'OCURRENCE/'+column | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="column !== 'status'" class="top-content">
                    {{ element[column] }}
                  </div>
                  <div *ngIf="column === 'status'" class="top-content">
                    <div *ngIf="role === 1 && !element.active; else statusDisplay" style="color: red;text-decoration: line-through;">
                       {{ 'OCCURRENCE_DELETED' | translate | uppercase }} 
                    </div>
                    <ng-template #statusDisplay>
                      <div *ngIf="element.status === 'SENT_TO_LSP' && role === 5" style="color: red"> {{ 'SENT_TO_LSP' | translate }} </div>
                      <div *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role === 5" style="color: green"> {{ 'OCCURRENCE_TO_RESOLVE' | translate }} </div>
                      <div *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role === 1" style="color: red"> {{ 'OCCURRENCE_TO_RESOLVE' | translate }} </div>
                      <div *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role !== 1 && role !== 5" style="color: red"> {{ 'OCCURRENCE_TO_RESOLVE' | translate }} </div>
                      <div *ngIf="element.status === 'OCCURRENCE_RESOLVED'" style="color: green"> {{ 'OCCURRENCE_RESOLVED' | translate }} </div>
                      <div *ngIf="element.status === 'OCCURRENCE_DENIED' && role === 5" style="color: green"> {{ 'OCCURRENCE_DENIED' | translate }} </div>
                    </ng-template>
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                  <div class="container-fluid"
                    [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <div class="expanded-columns column">
                      <div class="row justify-content-around">
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/createdby' | translate }}: </span> {{element.createdBy}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/date' | translate }}: </span> {{element.createdAt | date:'d/M/yy, h:mm a'}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/updatedby' | translate }}: </span> {{element.updatedBy}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/update' | translate }}: </span> {{element.updatedAt | date:'d/M/yy, h:mm a'}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/obs' | translate }}: </span> {{element.resolution}}</div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/image' | translate }}: </span></div>
                      </div>
                      <div class="row justify-content-around" >
                        <div class="col" *ngIf="element.file !== undefined">
                          <app-image-lightbox class="col" [images]="element.file" [showDate]="true"></app-image-lightbox>
                        </div>
                      </div>
                      <div *ngIf="element.active" class="row justify-content-end buttons-row">
                        <button class="blue col-8">
                          <app-upload-files [id]="element.id" [entity]="'occurrence'"
                            (messageEvent)="receiveFile($event)">
                          </app-upload-files>
                        </button>
                        <button
                          *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role !== 5 && role !== 3 && element.uoc !== 'Avaria / Claims' && element.uoc !== 'Anexo de Confirmação' || 
                                  role === 1 && element.uoc === 'Avaria / Claims' && element.status === 'OCCURRENCE_TO_RESOLVE' && element.uoc !== 'Anexo de Confirmação'"
                          class="green col"
                          (click)="openDialog(element)"> Resolver
                        </button>
                        <button *ngIf="role !== 5 && role !== 3 && element.status === 'OCCURRENCE_TO_RESOLVE' && element.uoc === 'Anexo de Confirmação'" class="green col"
                        (click)="openSpecialOccDialog(element)"> Resolver
                        </button>
                        <button *ngIf="(role !== 5 && role !== 3 && element.uoc !== 'Avaria / Claims') || (role === 1 && element.uoc === 'Avaria / Claims')" class="red col" 
                        (click)="removeOccurrence(element)" [ngClass]="{'fas-grey':mdr.lock === false}"> Remover
                        </button>
                        <button *ngIf="element.status === 'SENT_TO_LSP' && role === 5" class="green col"
                          (click)="approveOccurrence(element)">
                          {{ 'OCURRENCE/aprove' | translate }}
                        </button>
                        <button *ngIf="element.status === 'SENT_TO_LSP' && role === 5" class="red col"
                          (click)="rejectOccurrence(element)">
                          {{ 'OCURRENCE/reprove' | translate }}
                        </button>
                        <button *ngIf="element.canEdit === true" class="blue col"
                        (click)="openEditOccurrenceDialog(element)">
                          Editar
                      </button>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click) = "showAll(element)"
                (click)="expandedElement = expandedElement === element ? null : element">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #occurrenceEditionDialog>
  <h1 mat-dialog-title>Edição de Ocorrência</h1>
  <div mat-dialog-content>
    <p>Atenção: Essa Ocorrência pode ser editada somente nos 15 minutos posteriores a sua criação</p>
      <div class="row">
        <mat-form-field class="col-md-6">
          <mat-select placeholder="Tipo" [(ngModel)]="selectedOccEdit.uoc" name="uoc" required>
            <mat-option *ngFor="let type of uoc" [value]="type.name">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <input matInput style="width: 100%" placeholder="Descrição" name="text" [(ngModel)]="selectedOccEdit.text" required>
        </mat-form-field>
      </div>

  </div>
  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="{ edit: true, description: selectedOccEdit.text, type: selectedOccEdit.uoc }" cdkFocusInitial >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="{ edit: false }" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>
</ng-template>
