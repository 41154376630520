import { MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Occurrence, OCCURRENCE_STATUS } from '../../../models/occurrence';
import { MDR } from '../../../models/mdr';
import { MdrService } from '../../../providers/mdr.service';
import { UOCMDR } from '../../../models/uoc__mdr';
import { UOC } from '../../../models/uoc';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { UOCObservable } from '../../../services/uoc.service';
import { MdrObservable } from '../../../services/mdr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { COPQMDR } from '../../../models/copq__mdr';
import { UploadFileService } from '../../../providers/upload.service';
import { environment } from '../../../../environments/environment';
import { LogisticOrderComponent } from '../logistic-order.component';
var OccurrenceComponent = /** @class */ (function () {
    function OccurrenceComponent(dialog, mdrService, snackBar, aRoute, drService, drObservable, mdrObservable, uploadService, sanitizer, logisticOrder) {
        var _this = this;
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.aRoute = aRoute;
        this.drService = drService;
        this.drObservable = drObservable;
        this.mdrObservable = mdrObservable;
        this.uploadService = uploadService;
        this.sanitizer = sanitizer;
        this.logisticOrder = logisticOrder;
        this.base_url = environment.apiUrl;
        this.dataOccurrence = new MatTableDataSource();
        this.occurrenceColumns = ['id', 'uoc', 'text', 'status', 'createdAt', 'upload', 'file', 'resolution'];
        this.columnsToDisplay = ['id', 'uoc', 'text', 'status'];
        this.showAllRows = false;
        this.myControl = new FormControl();
        this.drList = [];
        this.allOccurrence = [];
        this.occurrence = new Occurrence();
        this.role = 0;
        this.mdr = new MDR;
        this.uocColumns = ['uoc', 'value', 'observation'];
        this.uoc = [];
        this.sumTotal = 0;
        this.showDeleted = false;
        this.data = [];
        this.copq = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.occurrenceColumns.push('action');
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            _this.drObservable.currentDR.subscribe(function (dr) {
                _this.drList = dr;
            });
            _this.getListOccurrence();
        });
        this.mdrService.getListCoPQ().subscribe(function (copq) {
            _this.copq = copq;
        });
        this.mdrService.getOccurrenceEnum().subscribe(function (data) {
            _this.uoc = data.map(function (e) {
                var u = new UOC();
                u.id = e.id;
                u.name = e.occurrence;
                return u;
            });
        });
    }
    OccurrenceComponent.prototype.createOccurrence = function (status, description, type, resolution) {
        var _this = this;
        this.occurrence.idMDR = this.mdr.id;
        // console.log(this.occurrence);
        if (status) {
            this.occurrence.status = status;
        }
        else {
            this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        }
        if (description) {
            this.occurrence.text = description;
        }
        if (type) {
            this.occurrence.uoc = type;
        }
        if (resolution) {
            this.occurrence.resolution = resolution;
        }
        if (['Status', 'Descarte de Embalagem'].includes(this.occurrence.uoc)) {
            this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        } // gambiarra, remover, corrigir TODO DEBUG; kkk remover, corrigir kkk
        this.mdrService.createOccurrence(this.occurrence).subscribe(function (response) {
            if (response && response.text === 'Occurrence save correctly') {
                _this.snackBar.open('Ocorrência criada com sucesso', 'Ok', { duration: 5000 });
            }
            _this.getListOccurrence();
            if (_this.occurrence.uoc === 'Avaria / Claims') {
                _this.logisticOrder.getMDR();
            }
        }, function (error) {
            if (error && error.error.text === 'Error: Cannot crete this type of Occurrence in this MDR status') {
                _this.snackBar.open('Essa ocorrência só pode ser criada no status "MDR Entregue com sucesso"', 'Ok', { duration: 5000 });
            }
            else {
                _this.snackBar.open('Não foi possível criar a Ocorrência', 'Ok', { duration: 5000 });
            }
        });
    };
    OccurrenceComponent.prototype.changeShowDeleted = function () {
        this.showDeleted = !this.showDeleted;
        var data = this.allOccurrence;
        if (this.showDeleted === false) {
            data = data.filter(function (occurrence) { return occurrence['active'] === true; });
        }
        this.dataOccurrence.data = data;
    };
    OccurrenceComponent.prototype.showAll = function (element) {
        this.showAllRows = !this.showAllRows;
        if (this.showAllRows) {
            this.uploadService.findByEntity(element.id, 'occurrence').subscribe(function (ans) {
                element.file = (ans.length === 0) ? undefined : ans;
            });
        }
    };
    OccurrenceComponent.prototype.getListOccurrence = function () {
        var _this = this;
        this.mdrService.getOccurences(this.mdr).subscribe(function (data) {
            data = data.map(function (d) {
                var createdAtDate = new Date(d.createdAt);
                var now = new Date();
                var differenceInMinutes = (now.getTime() - createdAtDate.getTime()) / (1000 * 60);
                d.canEdit = differenceInMinutes <= 15 && d.uoc !== 'Alteração de KM' && d.uoc !== 'Alteração de OVs';
                return d;
            });
            var occStatusToBeFiltered = [
                OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL,
                OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT,
                OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED,
            ];
            var occStatusToBeFilteredWithRoles = [
                OCCURRENCE_STATUS.SENT_TO_LSP,
                OCCURRENCE_STATUS.OCCURRENCE_DENIED
            ];
            var occWithoutCost = ['Aéreo', 'Km adicional', 'Drop', 'Ajudantes Extras', 'SAD', 'Retorno de Claims', 'Coleta Infrutífera', 'Balsa', 'Aguardando Aprovação de Custo Extra'];
            _this.allOccurrence = data.filter(function (occurrence) { return !occStatusToBeFiltered.includes(occurrence.status); });
            if (_this.role === 1 || _this.role === 77) {
                _this.allOccurrence = _this.allOccurrence.filter(function (occurrence) { return !occStatusToBeFilteredWithRoles.includes(occurrence.status); });
            }
            if (_this.role === 3) {
                _this.allOccurrence = _this.allOccurrence.filter(function (occurrence) { return !occWithoutCost.includes(occurrence.uoc); });
            }
            var data2 = _this.allOccurrence;
            if (_this.showDeleted === false) {
                data2 = data2.filter(function (occurrence) { return occurrence['active'] === true; });
            }
            _this.dataOccurrence = new MatTableDataSource(data2);
            _this.occurrence = new Occurrence();
        });
    };
    OccurrenceComponent.prototype.approveOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (_this.occurrence.uoc === 'COVID-19') {
                _this.mdrService.updateCoronaVirus(_this.drList).subscribe(function (el) {
                });
            }
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência enviada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    OccurrenceComponent.prototype.receiveFile = function ($event) {
        this.getListOccurrence();
    };
    OccurrenceComponent.prototype.resolveOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    OccurrenceComponent.prototype.removeOccurrence = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteOccDialogComponent, {
            width: '600px',
            data: {
                text: ''
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdrService.deleteOccurrence(occurrence).subscribe(function (data) {
                    _this.snackBar.open('Ocorrência deletada com sucesso', 'Ok', {
                        duration: 4000
                    });
                    _this.getListOccurrence();
                });
            }
        });
    };
    OccurrenceComponent.prototype.isArray = function (val) {
        return typeof val === 'object';
    };
    OccurrenceComponent.prototype.getSafeURL = function (element) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + element.fileName);
    };
    OccurrenceComponent.prototype.getUOCValue = function () {
        var _this = this;
        this.mdrService.getUOCValue(this.occurrence, this.mdr, false).subscribe(function (data) {
            _this.occurrence.value = data;
        });
    };
    OccurrenceComponent.prototype.openDialog = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                var description = (typeof response === 'string') ? response : (response.description ? response.description : response.text);
                occurrence.resolution = description;
                if (response.costApprovals === true) {
                    _this.createOccurrence(OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL, description, response.type, response.resolution);
                }
                _this.resolveOccurrence(occurrence);
            }
        });
    };
    OccurrenceComponent.prototype.openSpecialOccDialog = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptSpecialOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                var description = (typeof response === 'string') ? response : (response.description ? response.description : response.text);
                occurrence.resolution = description;
                _this.resolveOccurrence(occurrence);
            }
        });
    };
    OccurrenceComponent.prototype.openEditOccurrenceDialog = function (occurrence) {
        var _this = this;
        this.selectedOccEdit = Object.assign({}, occurrence);
        var dialog = this.dialog.open(this.occurrenceEditionDialog, {
            width: '600px',
            data: {
                uocList: this.uoc,
                occurrence: this.selectedOccEdit
            }
        });
        dialog.afterClosed().subscribe(function (dialogResponse) {
            if (dialogResponse && dialogResponse.edit === true) {
                var body = { id: _this.selectedOccEdit.id, description: dialogResponse.description, type: dialogResponse.type };
                _this.mdrService.editOccurrenceByDate(body).subscribe(function (response) {
                    if (response) {
                        _this.snackBar.open('Ocorrência editada com sucesso', 'Ok', {
                            duration: 4000,
                        });
                        _this.getListOccurrence();
                    }
                    else {
                        _this.snackBar.open('Erro ao editar Ocorrência', 'Ok', {
                            duration: 4000,
                        });
                    }
                });
            }
        });
    };
    OccurrenceComponent.prototype.rejectOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_DENIED;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    return OccurrenceComponent;
}());
export { OccurrenceComponent };
var AcceptSpecialOccurrenceDialogComponent = /** @class */ (function () {
    function AcceptSpecialOccurrenceDialogComponent(dialogRef, mdrService, uocObservable, snackBar, uploadService, data) {
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.uocObservable = uocObservable;
        this.snackBar = snackBar;
        this.uploadService = uploadService;
        this.data = data;
        this.uocSelected = 'Anexo de Confirmação';
        this.currentUser = null;
        this.role = 0;
        this.needsApproval = false;
        this.myControl = new FormControl();
        this.fileUploaded = false;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.data.needsAttachment = true;
        this.data.needsApproval = false;
        this.drList = data.drList;
        this.occ = data.occ;
        this.uocSelected = 'Anexo de Confirmação';
    }
    AcceptSpecialOccurrenceDialogComponent.prototype.onYesClick = function () {
        var uoc = new UOCMDR();
        uoc.idMDR = this.data.mdr.id;
        uoc.value = 0;
        uoc.uoc = 'Anexo de Confirmação';
        uoc.observation = this.data.text;
        uoc.needsAttachment = true;
        uoc.idOccurrence = this.occ.id;
        for (var i = 0; i < this.myControl.value.length; i++) {
            for (var j = 0; j < this.drList.length; j++) {
                if (this.drList[j].id === this.myControl.value[i]) {
                    uoc.drList.push(this.drList[j]);
                }
            }
        }
        this.mdrService.createUOC(uoc).subscribe(function (responseUOC) {
        });
        this.dialogRef.close({ description: this.data.text, type: this.uocSelected, resolution: this.valueUOC, costApprovals: this.needsApproval });
    };
    AcceptSpecialOccurrenceDialogComponent.prototype.receiveFile = function (event) {
        if (event === 'ok') {
            this.fileUploaded = true;
        }
    };
    AcceptSpecialOccurrenceDialogComponent.prototype.onNoClick = function () {
        this.data = 0;
        this.dialogRef.close();
    };
    return AcceptSpecialOccurrenceDialogComponent;
}());
export { AcceptSpecialOccurrenceDialogComponent };
var AcceptOccurrenceDialogComponent = /** @class */ (function () {
    function AcceptOccurrenceDialogComponent(dialogRef, mdrService, uocObservable, snackBar, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.uocObservable = uocObservable;
        this.snackBar = snackBar;
        this.data = data;
        this.isUOC = false;
        this.isCOPQ = false;
        this.needsApproval = false;
        this.totalValue = 0;
        this.currentUser = null;
        this.role = 0;
        this.uocMult = 1;
        this.copqFinalValue = 0;
        this.shouldCopyCOPQ = 0;
        this.copqMult = 1;
        this.valueCOPQ = 0;
        this.myControl = new FormControl();
        this.freightUOC = false;
        this.copyUOCValue = false;
        this.uocWithoutCost = 'Anexo de Confirmação';
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        // pega UOCs possíveis
        this.mdrService.getListUOC().subscribe(function (response) {
            _this.uoc = response;
        });
        this.data.needsAttachment = true;
        this.copq = data.copq;
        this.data.needsApproval = false;
        this.drList = data.drList;
        this.occ = data.occ;
        this.uocSelected = this.occ.uoc;
    }
    AcceptOccurrenceDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // se é UOC
        if (this.needsApproval === true) {
            this.mdrService.sendApproveCostEmail(this.data.mdr, []).subscribe(function (k) {
                // console.log(k);
                // console.log('Email enviado de aprovação');
                // this.dialogRef.close({text: this.data.text, costApprovals: this.needsApproval});
            });
            this.dialogRef.close({ description: this.data.text, type: this.uocSelected, resolution: this.valueUOC, costApprovals: this.needsApproval });
        }
        else {
            if (this.isUOC === true) {
                if (this.myControl.value === null) {
                    this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
                        duration: 4000,
                    });
                    return;
                }
                // nao permite criar UOC com value 0
                if ((this.uocSelected !== this.uocWithoutCost) && ((this.valueUOC * this.uocMult) === 0) || isNaN(this.valueUOC * this.uocMult)) {
                    this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
                        duration: 4000,
                    });
                    return;
                }
                var uoc_1 = new UOCMDR();
                uoc_1.idMDR = this.data.mdr.id;
                uoc_1.value = this.valueUOC;
                uoc_1.value = this.valueUOC * this.uocMult * 100;
                uoc_1.uoc = this.uocSelected;
                uoc_1.observation = this.data.text;
                uoc_1.needsAttachment = this.data.needsAttachment;
                uoc_1.idOccurrence = this.occ.id;
                for (var i = 0; i < this.myControl.value.length; i++) {
                    for (var j = 0; j < this.drList.length; j++) {
                        if (this.drList[j].id === this.myControl.value[i]) {
                            uoc_1.drList.push(this.drList[j]);
                        }
                    }
                }
                // cria a UOC
                this.mdrService.createUOC(uoc_1).subscribe(function (responseUOC) {
                    // atualiza lista de UOCs da MDR
                    _this.mdrService.getUOC(_this.data.mdr).subscribe(function (uocmdr) {
                        _this.uocObservable.changeUOC(uocmdr);
                    });
                    // se tambem é CoPQ
                    if (_this.isCOPQ === true) {
                        var copq = new COPQMDR();
                        copq.idMDR = _this.data.mdr.id;
                        // console.log((document.getElementById('valueCOPQ') as HTMLInputElement));
                        // if ( this.shouldCopyCOPQ === 1 ) {
                        copq.value = parseFloat(document.getElementById('valueCOPQ').value) * _this.copqMult * 100;
                        // } else {
                        // copq.value = this.valueCOPQ * this.copqMult * 100;
                        // }
                        copq.copq = _this.copqSelected;
                        if (_this.copqSelected === 'Falta de Acesso COVID - 19') {
                            _this.mdrService.updateCoronaVirus(uoc_1.drList).subscribe(function (el) {
                                // console.log(el);
                            });
                        }
                        _this.mdrService.createCoPQ(copq).subscribe(function (responseCOPQ) {
                            // console.log("Entrou aqui sendo COPQ");
                            // fecha dialog
                            _this.dialogRef.close({ text: _this.data.text, costApprovals: _this.needsApproval });
                        });
                    }
                    else { // nao é CoPQ
                        // fecha dialog
                        // console.log("Entrou aqui");
                        // console.log({text: this.data.text, costApprovals: this.needsApproval});
                        _this.dialogRef.close({ text: _this.data.text, costApprovals: _this.needsApproval });
                    }
                });
            }
            else { // nao é UOC
                // fecha dialog
                this.dialogRef.close(this.data.text);
            }
        }
    };
    AcceptOccurrenceDialogComponent.prototype.getUOCValue = function (elem) {
        var _this = this;
        // console.log('teste')
        this.mdrService.getUOCValue(elem, this.data.mdr, false).subscribe(function (data) {
            _this.valueUOC = data;
            _this.freightUOC = elem.name === 'Frete Minímo';
            // console.log(data);
        });
    };
    AcceptOccurrenceDialogComponent.prototype.onNoClick = function () {
        this.data = 0;
        this.dialogRef.close();
    };
    AcceptOccurrenceDialogComponent.prototype.copyUocValue = function () {
        this.copyUOCValue = !this.copyUOCValue;
    };
    AcceptOccurrenceDialogComponent.prototype.sanitizeInputPrice = function (field, value) {
        var input = value.srcElement.value;
        if (!Number(input)) {
            input = input.replace(/\./g, '');
            input = input.replace(',', '.');
            input = input.replace('R$', '');
            input = input.replace(' ', '');
        }
        this.valueUOC = input;
    };
    return AcceptOccurrenceDialogComponent;
}());
export { AcceptOccurrenceDialogComponent };
var ConfirmDeleteOccDialogComponent = /** @class */ (function () {
    function ConfirmDeleteOccDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        // console.log(translate);
    }
    ConfirmDeleteOccDialogComponent.prototype.ngOnInit = function () {
    };
    return ConfirmDeleteOccDialogComponent;
}());
export { ConfirmDeleteOccDialogComponent };
