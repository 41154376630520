import { Component, Inject, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Occurrence, OCCURRENCE_STATUS} from '../../../models/occurrence';
import {MDR} from '../../../models/mdr';
import {MdrService} from '../../../providers/mdr.service';
import {UOCMDR} from '../../../models/uoc__mdr';
import {UOC} from '../../../models/uoc';
import {FormControl} from '@angular/forms';
import {DR} from '../../../models/dr';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {UOCObservable} from '../../../services/uoc.service';
import {MdrObservable} from '../../../services/mdr.service';
import {Transportadora} from '../transport/transport.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Copq} from '../../../models/copq';
import {COPQMDR} from '../../../models/copq__mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {environment} from '../../../../environments/environment';
import { LogisticOrderComponent } from '../logistic-order.component';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-occurrence',
  templateUrl: './occurrence.component.html',
  styleUrls: ['./occurrence.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OccurrenceComponent {
  @ViewChild('occurrenceEditionDialog') occurrenceEditionDialog: any;
  selectedOccEdit;
  base_url = environment.apiUrl;
  dataOccurrence = new MatTableDataSource<Occurrence>();
  occurrenceColumns = ['id', 'uoc', 'text', 'status', 'createdAt', 'upload', 'file', 'resolution'];
  columnsToDisplay = ['id', 'uoc', 'text', 'status'];
  expandedElement: Occurrence | null;
  showAllRows = false;
  myControl = new FormControl();
  drList: DR[] = [];
  allOccurrence: Occurrence[] = [];
  occurrence: Occurrence = new Occurrence();
  currentUser: any;
  role = 0;
  mdr: MDR = new MDR;
  uocColumns = ['uoc', 'value', 'observation'];
  uoc: UOC[] = [];
  valueUOC: number;
  sumTotal = 0;
  showDeleted = false;
  data: DR[] = [];
  copq: Copq[] = [];
  dataSource = new MatTableDataSource<DR>(this.data);

  constructor(
    public dialog: MatDialog,
    public mdrService: MdrService,
    public snackBar: MatSnackBar,
    public aRoute: ActivatedRoute,
    private drService: DrService,
    public drObservable: DrObservable,
    public mdrObservable: MdrObservable,
    public uploadService: UploadFileService,
    private sanitizer: DomSanitizer,
    public logisticOrder: LogisticOrderComponent
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.occurrenceColumns.push('action');

    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.drObservable.currentDR.subscribe(dr => {
        this.drList = dr;
      });
      this.getListOccurrence();
    });

    this.mdrService.getListCoPQ().subscribe( copq => {
      this.copq = copq;
    });

    this.mdrService.getOccurrenceEnum().subscribe((data) => {
      this.uoc = data.map((e) => {
        const u = new UOC();
        u.id = e.id;
        u.name = e.occurrence;
        return u;
      });
    });
  }

  createOccurrence(status?: OCCURRENCE_STATUS, description?: string, type?: string, resolution?: string) {
    this.occurrence.idMDR = this.mdr.id;
    // console.log(this.occurrence);
    if (status) {
      this.occurrence.status = status;
    } else {
      this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
    }

    if (description) {
      this.occurrence.text = description;
    }

    if (type) {
      this.occurrence.uoc = type;
    }

    if (resolution) {
      this.occurrence.resolution = resolution;
    }

    if (['Status', 'Descarte de Embalagem'].includes(this.occurrence.uoc)) { this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED; } // gambiarra, remover, corrigir TODO DEBUG; kkk remover, corrigir kkk
    this.mdrService.createOccurrence(this.occurrence).subscribe((response) => {
      if (response && response.text === 'Occurrence save correctly') {
        this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {duration: 5000});
      }
      this.getListOccurrence();
      if (this.occurrence.uoc === 'Avaria / Claims') {
        this.logisticOrder.getMDR();
      }
    }, (error) => {
      if (error && error.error.text === 'Error: Cannot crete this type of Occurrence in this MDR status') {
        this.snackBar.open('Essa ocorrência só pode ser criada no status "MDR Entregue com sucesso"', 'Ok', {duration: 5000});
      } else {
        this.snackBar.open('Não foi possível criar a Ocorrência', 'Ok', {duration: 5000});
      }
    });
  }

  changeShowDeleted() {
    this.showDeleted = !this.showDeleted;
    let data: Occurrence [] = this.allOccurrence;
    if (this.showDeleted === false) {
      data = data.filter(occurrence => occurrence['active'] === true);
    }

    this.dataOccurrence.data = data;
  }

  showAll(element: any) {
    this.showAllRows = !this.showAllRows;
    if (this.showAllRows) {
      this.uploadService.findByEntity(element.id, 'occurrence').subscribe( (ans) => {
        element.file = (ans.length === 0) ? undefined : ans;
      });
    }
  }

  getListOccurrence() {
    this.mdrService.getOccurences(this.mdr).subscribe((data: (Occurrence & { canEdit?: boolean })[]) => {
      data = data.map((d) => {
        const createdAtDate = new Date(d.createdAt);

        const now = new Date();
        const differenceInMinutes = (now.getTime() - createdAtDate.getTime()) / (1000 * 60);

        d.canEdit = differenceInMinutes <= 15 && d.uoc !== 'Alteração de KM' && d.uoc !== 'Alteração de OVs';
        return d;
      });

      const occStatusToBeFiltered: string[] = [
        OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL,
        OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT,
        OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED,
      ];

      const occStatusToBeFilteredWithRoles: string[] = [
        OCCURRENCE_STATUS.SENT_TO_LSP,
        OCCURRENCE_STATUS.OCCURRENCE_DENIED
      ];

      const occWithoutCost = ['Aéreo', 'Km adicional', 'Drop', 'Ajudantes Extras', 'SAD', 'Retorno de Claims', 'Coleta Infrutífera', 'Balsa', 'Aguardando Aprovação de Custo Extra'];

      this.allOccurrence = data.filter( (occurrence) => !occStatusToBeFiltered.includes(occurrence.status));

      if (this.role === 1 || this.role === 77) {
        this.allOccurrence = this.allOccurrence.filter(occurrence => !occStatusToBeFilteredWithRoles.includes(occurrence.status));
      }
      if (this.role === 3) {
        this.allOccurrence = this.allOccurrence.filter(occurrence => !occWithoutCost.includes(occurrence.uoc));
      }

      let data2: Occurrence [] = this.allOccurrence;
      if (this.showDeleted === false) {
        data2 = data2.filter(occurrence => occurrence['active'] === true);
      }
      this.dataOccurrence = new MatTableDataSource<Occurrence>(data2);
      this.occurrence = new Occurrence();
    });
  }

  approveOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (this.occurrence.uoc === 'COVID-19') {
        this.mdrService.updateCoronaVirus(this.drList).subscribe(el => {
        });
      }
      if (response !== undefined) {
        this.snackBar.open('Ocorrência enviada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  receiveFile($event) {
    this.getListOccurrence();
  }

  resolveOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  removeOccurrence(occurrence: Occurrence) {
    const dialogRef = this.dialog.open(ConfirmDeleteOccDialogComponent, {
      width: '600px',
      data: {
        text: ''
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response[0] === true) {
        this.mdrService.deleteOccurrence(occurrence).subscribe((data) => {
          this.snackBar.open('Ocorrência deletada com sucesso', 'Ok', {
            duration: 4000
          });
          this.getListOccurrence();
        });
      }
    });
  }

  isArray(val) {
    return typeof val === 'object';
  }

  getSafeURL(element: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + element.fileName);
  }

  getUOCValue() {
    this.mdrService.getUOCValue(this.occurrence, this.mdr, false).subscribe( (data) => {
      this.occurrence.value = data;
    });
  }

  openDialog(occurrence: Occurrence): void {
    const dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        const description = (typeof response === 'string') ? response : (response.description ? response.description : response.text);

        occurrence.resolution = description;

        if (response.costApprovals === true) {
          this.createOccurrence(OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL, description, response.type, response.resolution);
        }

        this.resolveOccurrence(occurrence);
      }
    });
  }

  openSpecialOccDialog(occurrence: Occurrence) {
    const dialogRef = this.dialog.open(AcceptSpecialOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        const description = (typeof response === 'string') ? response : (response.description ? response.description : response.text);

        occurrence.resolution = description;

        this.resolveOccurrence(occurrence);
      }
    });
  }

  openEditOccurrenceDialog(occurrence: Occurrence) {
    this.selectedOccEdit = Object.assign({}, occurrence);
    const dialog = this.dialog.open(this.occurrenceEditionDialog, {
      width: '600px',
      data: {
        uocList: this.uoc,
        occurrence: this.selectedOccEdit
      }
    });
    dialog.afterClosed().subscribe((dialogResponse: { edit: boolean, description: string, type: string }) => {
      if (dialogResponse && dialogResponse.edit === true) {
        const body = { id: this.selectedOccEdit.id, description: dialogResponse.description, type: dialogResponse.type };
        this.mdrService.editOccurrenceByDate(body).subscribe((response) => {
          if (response) {
            this.snackBar.open('Ocorrência editada com sucesso', 'Ok', {
              duration: 4000,
            });
            this.getListOccurrence();
          } else {
            this.snackBar.open('Erro ao editar Ocorrência', 'Ok', {
              duration: 4000,
            });
          }
        });
      }
    });
  }

  rejectOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_DENIED;

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

}

@Component({
  selector: 'app-accept-occurrence-dialog',
  templateUrl: 'accept-special-type-occ.html',
  styleUrls: ['./occurrence.component.scss']
})

export class AcceptSpecialOccurrenceDialogComponent {
  uocSelected: any = 'Anexo de Confirmação';
  mdr: any;
  currentUser = null;
  valueUOC;
  role = 0;
  uoc: UOC[];
  needsApproval = false;
  drList: DR[];
  myControl = new FormControl();
  occ: any;
  fileUploaded = false;

  constructor(
    public dialogRef: MatDialogRef <AcceptSpecialOccurrenceDialogComponent>,
    public mdrService: MdrService,
    public uocObservable: UOCObservable,
    public snackBar: MatSnackBar,
    public uploadService: UploadFileService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.data.needsAttachment = true;
    this.data.needsApproval = false;
    this.drList = data.drList;
    this.occ = data.occ;
    this.uocSelected = 'Anexo de Confirmação';
  }

  onYesClick(): void {
    const uoc = new UOCMDR();
    uoc.idMDR = this.data.mdr.id;
    uoc.value = 0;
    uoc.uoc = 'Anexo de Confirmação';
    uoc.observation = this.data.text;
    uoc.needsAttachment = true;
    uoc.idOccurrence = this.occ.id;
    for (let i = 0; i < this.myControl.value.length; i++) {
      for (let j = 0; j < this.drList.length; j++) {
        if (this.drList[j].id === this.myControl.value[i]) {
          uoc.drList.push(this.drList[j]);
        }
      }
    }
    this.mdrService.createUOC(uoc).subscribe((responseUOC) => {
    });
    this.dialogRef.close({description: this.data.text, type: this.uocSelected, resolution: this.valueUOC  , costApprovals: this.needsApproval});
  }

  receiveFile(event) {
    if (event === 'ok') {
      this.fileUploaded = true;
    }
  }

  onNoClick(): void {
    this.data = 0;
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-accept-occurrence-dialog',
  templateUrl: 'accept-occurrence-dialog.html',
  styleUrls: ['./occurrence.component.scss']
})
export class AcceptOccurrenceDialogComponent {
  isUOC = false;
  isCOPQ = false;
  copqSelected: any;
  uocSelected: any;
  valueUOC: any;
  numberValueUOC: number;
  needsApproval = false;
  mdr: any;
  totalValue = 0;
  currentUser = null;
  role = 0;
  uoc: UOC[];
  uocMult = 1;
  copqFinalValue = 0;
  shouldCopyCOPQ = 0;
  copqMult = 1;
  valueCOPQ = 0;
  copq: Copq[];
  drList: DR[];
  myControl = new FormControl();
  occ: any;
  freightUOC = false;
  copyUOCValue = false;
  uocWithoutCost = 'Anexo de Confirmação';


  constructor(
    public dialogRef: MatDialogRef <AcceptOccurrenceDialogComponent>,
    public mdrService: MdrService,
    public uocObservable: UOCObservable,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    // pega UOCs possíveis
    this.mdrService.getListUOC().subscribe((response) => {
      this.uoc = response;
    });
    this.data.needsAttachment = true;
    this.copq = data.copq;
    this.data.needsApproval = false;
    this.drList = data.drList;
    this.occ = data.occ;
    this.uocSelected = this.occ.uoc;
  }

  onYesClick(): void {
    // se é UOC
    if (this.needsApproval === true ) {
      this.mdrService.sendApproveCostEmail(this.data.mdr, []).subscribe(k => {
        // console.log(k);
        // console.log('Email enviado de aprovação');
        // this.dialogRef.close({text: this.data.text, costApprovals: this.needsApproval});
      });
      this.dialogRef.close({description: this.data.text, type: this.uocSelected, resolution: this.valueUOC  , costApprovals: this.needsApproval});
    } else {
      if (this.isUOC === true) {
        if (this.myControl.value === null) {
          this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
            duration: 4000,
          });
          return;
        }
        // nao permite criar UOC com value 0
        if ( (this.uocSelected !== this.uocWithoutCost) && ((this.valueUOC * this.uocMult) === 0) || isNaN(this.valueUOC * this.uocMult) ) {
          this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
            duration: 4000,
          });
          return;
        }
        const uoc = new UOCMDR();
        uoc.idMDR = this.data.mdr.id;
        uoc.value = this.valueUOC;
        uoc.value = this.valueUOC * this.uocMult * 100;
        uoc.uoc = this.uocSelected;
        uoc.observation = this.data.text;
        uoc.needsAttachment = this.data.needsAttachment;
        uoc.idOccurrence = this.occ.id;
        for (let i = 0; i < this.myControl.value.length; i++) {
          for (let j = 0; j < this.drList.length; j++) {
            if (this.drList[j].id === this.myControl.value[i]) {
              uoc.drList.push(this.drList[j]);
            }
          }
        }
        // cria a UOC
        this.mdrService.createUOC(uoc).subscribe((responseUOC) => {
          // atualiza lista de UOCs da MDR
          this.mdrService.getUOC(this.data.mdr).subscribe((uocmdr) => {
            this.uocObservable.changeUOC(uocmdr);
          });
          // se tambem é CoPQ
          if (this.isCOPQ === true) {
            const copq = new COPQMDR();
            copq.idMDR = this.data.mdr.id;
            // console.log((document.getElementById('valueCOPQ') as HTMLInputElement));
            // if ( this.shouldCopyCOPQ === 1 ) {
              copq.value = parseFloat((document.getElementById('valueCOPQ') as HTMLInputElement).value) * this.copqMult * 100;
            // } else {
              // copq.value = this.valueCOPQ * this.copqMult * 100;
            // }

            copq.copq = this.copqSelected;
            if (this.copqSelected === 'Falta de Acesso COVID - 19') {
              this.mdrService.updateCoronaVirus(uoc.drList).subscribe(el => {
                // console.log(el);
              });
            }
            this.mdrService.createCoPQ(copq).subscribe((responseCOPQ) => {
              // console.log("Entrou aqui sendo COPQ");
              // fecha dialog
              this.dialogRef.close({text: this.data.text, costApprovals: this.needsApproval});
            });
          } else { // nao é CoPQ
            // fecha dialog
            // console.log("Entrou aqui");
            // console.log({text: this.data.text, costApprovals: this.needsApproval});
            this.dialogRef.close({text: this.data.text, costApprovals: this.needsApproval});
          }
        });
      } else { // nao é UOC
        // fecha dialog
        this.dialogRef.close(this.data.text);
      }
    }
  }

  getUOCValue(elem) {
    // console.log('teste')
    this.mdrService.getUOCValue(elem, this.data.mdr, false).subscribe((data) => {
      this.valueUOC = data;
      this.freightUOC = elem.name === 'Frete Minímo';


      // console.log(data);
    });
  }

  onNoClick(): void {
    this.data = 0;
    this.dialogRef.close();
  }

  copyUocValue() {
    this.copyUOCValue = !this.copyUOCValue;
  }

  sanitizeInputPrice(field: string, value: any) {
    let input = value.srcElement.value;
    if (!Number(input)) {
      input = input.replace(/\./g, '');
      input = input.replace(',', '.');
      input = input.replace('R$', '');
      input = input.replace(' ', '');
    }

    this.valueUOC = input;
  }

}

@Component({
  selector: 'app-delete-occurrence-dialog',
  templateUrl: 'delete-occurrence-dialog.html',
  styleUrls: ['./occurrence.component.scss']
})
export class ConfirmDeleteOccDialogComponent {
    constructor(
      public dialogRef: MatDialogRef<ConfirmDeleteOccDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data) {
      // console.log(translate);
    }
    ngOnInit() {
    }
  }
