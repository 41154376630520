import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {Zhstatus} from '../models/zhstatus';
import {Zrsdpac} from '../models/zrsdpac';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class OVService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAll(id: number): Observable<any> {
    if (id === null) { return null; }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/' + id, {headers: header});
  }

  saveMultipleOV(listOV: OV[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV', {
      listOV: listOV
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }

  saveMultipleZH(listZH: Zhstatus[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/zh', {
      listZH: listZH
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }

  saveMultipleZRS(listZRS: Zrsdpac[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/zrs', {
      listZRS: listZRS
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }

  getNfRelationsByMdr(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/' + id + '/relations', {headers: header});
  }

  getNfNotRelationsByMdr(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/' + id + '/drId', {headers: header});
  }

  getNfByMdr(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/nf/' + id, {headers: header})
      .map((response) => {
        const OVdata = response;
        return OVdata;
      });
  }

  addToDR(mdrId: number, drId: number, toAdd: any[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
                                    .set('x-api-key', api_key);
    return this.http.post(base_url + `OV/${mdrId}/relations`, {
      drId: drId,
      ovs: toAdd.filter((row) => row.type === 'zvsd1')
                .map((row) => row.referenceNoNf),
      nfs: toAdd.filter((row) => row.type === 'nfe')
                .map((row) => row.invoiceNumber),
    }, {headers: header});
  }

  sendHubReturn(toRemove: any[], hub: string ): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
                                    .set('x-api-key', api_key);
    const body = {
      ov: toRemove,
      hubToSend: hub
    };
  return this.http.post(base_url + 'OV/sendDRToHub', body, { headers: header });
  }

  removeFromMDR(mdrId: number, body: Record<string, string[]>): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token)
                                    .set('x-api-key', api_key);
    const options = {
      headers: header,
      body: body
    };
    return this.http.delete(base_url + `OV/${mdrId}/relations`, options);
  }

  validateOVs(idList: string[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/validate', {
      idList: idList
    }, {headers: header})
               .map((response) => {
                 return response;
               });
  }

  validateHub(hubName: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'OV/validateHUB', {
      hub: hubName
    }, {headers: header})
               .map((response) => {
                 return response;
               });
  }

  getVehicle(ovs: number[], products: string[] = null): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    let params = new HttpParams().set('ovs', '[' + ovs.toString() + ']');

    if (products) {
      params = params.set('products', '[' + products.toString() + ']');
    }

    return this.http.get(base_url +  'OV/vehicle', {headers: header, params: params});
  }

  searchByOV(ov: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/search/' + ov, {headers: header});
  }

  getFilesFromMdrByOv(idMdr: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/mdr/' + idMdr + '/zvsd1', {headers: header});
  }

  getFilesFromMdrByNf(idMdr: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/mdr/' + idMdr + '/nfe', {headers: header});
  }

  getFilesFromMdrByOvForPOD(idMdr: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/zvsd1', {headers: header});
  }

  getFilesFromMdrByNfForPOD(idMdr: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/nfe', {headers: header});
  }

  getFilesFromMdrByCteForPOD(idMdr: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/pod/mdr/' + idMdr + '/cte', {headers: header});
  }

  getFilesFromMdrManually(idMdr: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/mdr/' + idMdr + '/manual', {headers: header});
  }
}
